
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Typography } from 'antd';
import moment from 'moment';
const { Title, Paragraph, Text, Link } = Typography;

const styles = StyleSheet.create({
    container: {
        transition: 'background-color 0.2s ease',
        border: '1px solid lightgrey',
        borderRadius: '2px',
        padding: '8px',
        marginBottom: '8px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row'
    },
    dragging: {
        transition: 'background-color 0.2s ease',
        backgroundColor: 'lightblue',
    },
    handle: {
        width: '20px',
        height: '20px',
        backgroundColor: '#2196f3',
        borderRadius: '4px',
    }
});

const dueDate = (duedate) => {
    if (duedate) {
        return moment(duedate).format("MM/DD");
    } else {
        return '';
    }
};
export default class StaticTask extends React.Component {
    render() {
        return (
            <View>
                <View style={[styles.container]}>
                    <Text>{this.props.index + 1}:&nbsp;</Text>
                    <Link href={this.props.task.link} target="_blank" style={{ paddingRight: '4px' }}>{this.props.task.content}</Link>
                    <View style={{ marginLeft: 'auto' }}>
                        <Text>{this.props.task.creator ? this.props.task.creator.split('@')[0] : ''}</Text>
                        <Text style={{ paddingLeft: '8px', paddingRight: '8px', backgroundColor: 'lightgrey', borderRadius: '10px' }}>{dueDate(this.props.task.duedate)}</Text>

                    </View>
                </View>
            </View >
        )
    }
}