const ajaxCall = async (url, token) => {
    try {
        const response = await fetch(url, { 'headers': new Headers({ Authorization: 'Bearer ' + token }) });
        const json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export default ajaxCall;