import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import ajaxCall from "./shared-components/ajaxCall";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Linking } from "react-native";
import MyTickets from "./pages/MyTickets";
import AllTickets from "./pages/AllTickets";


export default function App() {
  const [userInfo, setUserInfo] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [allUsers, setAllUsers] = useState(undefined);
  global.jiraToken = 'b2749415-7da8-475c-b4e2-f340677b1342';
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("auth")) {
    var token = urlParams.get("auth");
    global.token = token;
  }

  const getUserInfo = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://wrapperapi.integrityprodserver.com/api/users/me",
          { headers: new Headers({ Authorization: "Bearer " + global.token }) }
        );
        const json = await response.json();
        setUserInfo(json);
        global.userInfo = json;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getAllUsers = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://wrapperapi.integrityprodserver.com/api/users",
          { headers: new Headers({ Authorization: "Bearer " + global.token }) }
        );
        const json = await response.json();
        setAllUsers(json);
        global.allUsers = json;
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (userInfo) {
      setUser(userInfo.email.split('@')[0]);
      global.user = userInfo.email.split('@')[0];
    }
  }, [userInfo]);


  useEffect(() => {
    getUserInfo();
    getAllUsers();
  }, [token]);


  useEffect(() => {
    if (user && user == userInfo.email.split('@')[0]) {
      ajaxCall('https://data.integrityprodserver.com/jira/trackView.php?user=' + user, global.jiraToken);
    }
  }, [user]);



  if (global.user !== undefined && global.allUsers !== undefined && global.userInfo !== undefined) {
    return (
      <View style={styles.center}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/mytickets" element={<MyTickets />} />
            <Route exact path="/alltickets" element={<AllTickets />} />
          </Routes>
        </BrowserRouter>
      </View>
    )
  } else {
    return (
      <View style={styles.center}>
        <ActivityIndicator />
      </View>
    )
  }
};

const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: '20px',
  },
  container: {
    display: 'flex',
  },
});