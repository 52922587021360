import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import StaticColumn from '../shared-components/StaticColumn';
import ajaxCall from "../shared-components/ajaxCall";
import moment from 'moment';
import { useInternalNotification } from 'antd/es/notification/useNotification';

export default function AllTickets() {
    if (userInfo.department.id == 2) {
        var link = "https://ircuwd.atlassian.net/jira/servicedesk/projects/IT/queues/issue/"
    } else {
	var link = "https://ircuwd.atlassian.net/browse/";
    }
    const [state, setState] = useState([]);
    const initialData = {
        tasks: {
        },
        columns: {
            'scheduled': {
                id: 'scheduled',
                title: 'Scheduled',
                taskIds: []
            },
            'ondeck': {
                id: 'ondeck',
                title: 'On Deck',
                taskIds: []
            },
            'unsorted': {
                id: 'unsorted',
                title: 'Unsorted (Not Doing)',
                taskIds: []
            },
        },
        columnOrder: ['scheduled', 'ondeck', 'unsorted']
    }
    const getTickets = async () => {
        var tasks = await ajaxCall('https://data.integrityprodserver.com/jira/getOpenJiraTasks.php', global.jiraToken);
        tasks.map((task, index) => {
            var multiplier = 0;
            if (task.fields.reporter !== undefined && task.fields.reporter !== null && task.fields.reporter.displayName !== undefined && !task.fields.reporter.displayName.includes('@')) {
                var userName = task.fields.reporter.displayName;
                user = allUsers.find(users => users.email.split('@')[0] == userName);
                if (user === undefined) {
                    user = allUsers.find(users => users.name == userName);
                }
            } else if (task.fields.reporter !== undefined && task.fields.reporter !== null && task.fields.reporter.emailAddress !== undefined){
                var userName = task.fields.reporter.emailAddress;
                user = allUsers.find(users => users.email.split('@')[0] == userName.split('@')[0]);
            }
            if (user !== undefined) {
                multiplier = user.multiplier;
                userName = user.name;
            } else {
                multiplier = 1;
            }
            tasks[index].multiplier = multiplier;
            tasks[index].user = userName;
        })
        var tasksAscending = [...tasks].sort((a, b) => {
            var aMultiplier = 0;
            var bMultiplier = 0;
            if (a.fields.labels.includes('MainInitiative')) {
                aMultiplier = a.multiplier * .9;
            } else {
                aMultiplier = a.multiplier;
            }
            if (b.fields.labels.includes('MainInitiative')) {
                bMultiplier = b.multiplier * .9;
            } else {
                bMultiplier = b.multiplier;
            }
            return ((a.fields.customfield_10076 * aMultiplier) - (b.fields.customfield_10076 * bMultiplier))
        });
        tasks = tasksAscending;
        tasks.map((task, index) => {
            if (task.fields.customfield_10070 !== null && (task.fields.customfield_10070.value == 'New System/Process' || task.fields.customfield_10070.value == 'Update/Improve Existing System/Process')) {
                if (task.fields.duedate !== null) {
                    var set = initialData.columns.scheduled;
                } else {
                    if (task.fields.customfield_10076 && task.fields.customfield_10076 != 9999) {
                        var set = initialData.columns.ondeck;
                    } else if (task.fields.customfield_10070 !== null) {
                        var set = initialData.columns.unsorted;
                    }
                }
            }

            if (set) {


                initialData.tasks[task.id] = { id: task.id, content: task.fields.summary, link: link + task.key, duedate: task.fields.duedate, creator: task.user };
                set.taskIds.push(task.id);
            }
            var set = null;
        });
        setState(initialData);
    };

    useEffect(() => {
        getTickets();
    }, []);


    if (state.columnOrder) {
        return (
            <View style={styles.center}>
                <div style={styles.container}>
                    {
                        state.columnOrder.map(columnId => {
                            const column = state.columns[columnId];
                            var tasks = column.taskIds.map(taskId => state.tasks[taskId]);
                            if (column.id == 'scheduled') {
                                var tasksAscending = [...tasks].sort((a, b) => {
                                    return moment(a['duedate']).unix() - moment(b['duedate']).unix()
                                });
                                tasks = tasksAscending;
                            }
                            return <StaticColumn key={column.id} column={column} tasks={tasks} />;
                        })
                    }
                </div>
            </View>
        )
    } else {
        return (
            <View style={styles.center}>
                <ActivityIndicator />
            </View>
        )
    }

}

const styles = StyleSheet.create({
    center: {
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    container: {
        display: 'flex',
    },
});
