
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Typography } from 'antd';
import moment from 'moment';
const { Title, Paragraph, Text, Link } = Typography;

import { Draggable } from 'react-beautiful-dnd';

const styles = StyleSheet.create({
    container: {
        transition: 'background-color 0.2s ease',
        border: '1px solid lightgrey',
        borderRadius: '2px',
        padding: '8px',
        marginBottom: '8px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row'
    },
    dragging: {
        transition: 'background-color 0.2s ease',
        backgroundColor: 'lightblue',
    },
    handle: {
        width: '20px',
        height: '20px',
        backgroundColor: '#2196f3',
        borderRadius: '4px',
    }
});

const lock = (duedate) => {
    if (duedate) {
        return <Text>🔒</Text>;
    } else {
        return <Text>&#9776;</Text>;
    }
};
const dueDate = (duedate) => {
    if (duedate) {
        console.log(duedate)
        return <Text>{moment(duedate).format("MM/DD")}</Text>;
    } else {
        return <View></View>;
    }
};
export default class DraggableTask extends React.Component {
    render() {
        return (
            <Draggable draggableId={this.props.task.id} index={this.props.index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <View style={[styles.container, snapshot.isDragging ? styles.dragging : '']}>
                            <div {...provided.dragHandleProps} style={{ display: 'flex' }}>
                                <Text>{this.props.index + 1}:&nbsp;</Text>
                                <View style={{ marginRight: '8px', }}>{lock(this.props.task.duedate)}</View>
                            </div>
                            <Link href={this.props.task.link} target="_blank" style={{ paddingRight: '4px' }}>{this.props.task.content}</Link>
                            <View style={this.props.task.duedate ? { marginLeft: 'auto', paddingLeft: '8px', paddingRight: '8px', backgroundColor: 'lightgrey', borderRadius: '10px' } : ''}>
                                {dueDate(this.props.task.duedate)}
                            </View>
                        </View>
                    </div >
                )
                }
            </Draggable>
        )
    }
}