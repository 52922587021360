
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Typography } from 'antd';
import StaticTask from './StaticTask';
const { Title } = Typography;

const styles = StyleSheet.create({
    container: {
        margin: '8px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
    },
    title: {
        padding: '8px'
    },
    header: {
        paddingBottom: '3px',
        borderBottomColor: 'lightgrey',
        borderBottomWidth: '1px',
    },
    taskList: {
        border: '1px solid lightgrey',
        padding: '8px',
        borderTopColor: '#2196f3',
        borderTopWidth: '10px',
        borderRadius: '10px',
    },
    available: {
        border: '5px dashed lightgrey',
        borderRadius: '10px',
        padding: '8px',
        marginBottom: '8px',
    },
});

export default class StaticColumn extends React.Component {
    render() {
        var availableSlot = <View></View>;
        if (this.props.column.id == 'main') {
            var count = <Text style={styles.header}>Availible Slots:&nbsp;{this.props.availableSlots}</Text>;
            if (this.props.availableSlots > 0) {
                var availableSlot = <View style={styles.available}><Text style={{ color: 'lightgrey' }}>+</Text></View>;
            }
        } else {
            var count = <Text style={styles.header}>Total:&nbsp;{this.props.tasks.length}</Text>;
        }
        return (
            <View style={styles.container}>
                <Title level={2} style={styles.title}>{this.props.column.title}</Title>
                <View>
                    <View
                        style={[styles.taskList]}
                    >
                        {count}
                        <View style={styles.container}>
                            {this.props.tasks.map((task, index) => <StaticTask key={task.id} task={task} index={index} />)}
                        </View>
                        <View>
                            {Array.from({ length: this.props.availableSlots }).map((_, index) => (<View key={index}>{availableSlot}</View>))}
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}