
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Typography } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import DraggableTask from './DraggableTask';
const { Title } = Typography;

const styles = StyleSheet.create({
    container: {
        margin: '8px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
    },
    title: {
        padding: '8px'
    },
    header: {
        paddingBottom: '3px',
        borderBottomColor: 'lightgrey',
        borderBottomWidth: '1px',
    },
    taskList: {
        border: '1px solid lightgrey',
        padding: '8px',
        borderTopColor: '#2196f3',
        borderTopWidth: '10px',
        borderRadius: '10px',
    },
    available: {
        border: '5px dashed lightgrey',
        borderRadius: '10px',
        padding: '8px',
        marginBottom: '8px',
    },
});

export default class DroppableColumn extends React.Component {

    render() {
        var availableSlot = <View></View>;
        if (this.props.column.id == 'main') {
            var count = <Text style={styles.header}>Availible Slots:&nbsp;{this.props.availableSlots}</Text>;
            if (this.props.availableSlots > 0) {
                var availableSlot = <View style={styles.available}><Text style={{ color: 'lightgrey' }}>+</Text></View>;
            }
        } else {
            var count = <Text style={styles.header}>Total:&nbsp;{this.props.tasks.length}</Text>;
        }
        return (
            <div style={styles.container}>
                <Title level={2} style={styles.title}>{this.props.column.title}</Title>
                <Droppable droppableId={this.props.column.id}>
                    {(provided, snapshot) => (
                        <View>
                            <View
                                ref={provided.innerRef}
                                style={[styles.taskList]}
                                {...provided.droppableProps}
                            >
                                {count}
                                <View style={styles.container}>
                                    {this.props.tasks.map((task, index) => <DraggableTask key={task.id} task={task} index={index} />)}
                                </View>
                                {provided.placeholder}
                                <View>
                                    {Array.from({ length: this.props.availableSlots }).map((_, index) => (<View key={index}>{availableSlot}</View>))}
                                </View>
                            </View>
                        </View>

                    )}
                </Droppable>
            </div>
        )
    }
}