import { StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { AutoComplete, Button } from "antd";
import { DragDropContext } from 'react-beautiful-dnd';
import { Select } from 'antd';
import DroppableColumn from '../shared-components/DroppableColumn';
import ajaxCall from "../shared-components/ajaxCall";
import { Linking } from "react-native";


export default function MyTickets() {
    const [state, setState] = useState([]);
    const [availableSlots, setAvailableSlots] = useState(0);
    const [employees, setEmployees] = useState(undefined);
    const [viewUser, setViewUser] = useState(user);
    var maxAvailable = 1;

    const initialData = {
        tasks: {
        },
        columns: {
            'main': {
                id: 'main',
                title: 'Main Initiatives',
                taskIds: []
            },
            'projects': {
                id: 'projects',
                title: 'Projects',
                taskIds: []
            },
            'openIssues': {
                id: 'openIssues',
                title: 'Open Issues / Unprocessed',
                taskIds: []
            },
        },
        columnOrder: ['main', 'projects', 'openIssues']
    }



    if (userInfo && !employees) {
        var e = [];
        e.push({ value: user, label: userInfo.name });
        userInfo.employees.map(emp => {
            var thisName = emp.name;
            var thisUser = emp.email.split('@')[0];
            e.push({ value: thisUser, label: thisName });
        })
        setEmployees(e);
    }

    const getTickets = async () => {
        if (viewUser) {
            var tasks = await ajaxCall('https://data.integrityprodserver.com/jira/getOpenJiraTasks.php?user=' + viewUser, global.jiraToken);
            tasks.map((task, index) => {
                if (!task.fields.customfield_10076) {
                    tasks[index].fields.customfield_10076 = 9999;
                }
            })
            const tasksAscending = [...tasks].sort((a, b) => a.fields.customfield_10076 - b.fields.customfield_10076);
            tasks = tasksAscending;
            tasks.map((task, index) => {
                if (task.fields.labels.includes('MainInitiative')) {
                    var set = initialData.columns.main;
		    initialData.tasks[task.id] = { id: task.id, content: task.fields.summary, link: "https://ircuwd.atlassian.net/browse/" + task.key, duedate: task.fields.duedate};
		    set.taskIds.push(task.id);
                } else {
                    if (task.fields.customfield_10070 !== null && (task.fields.customfield_10070.value == 'New System/Process' || task.fields.customfield_10070.value == 'Update/Improve Existing System/Process')) {
                        var set = initialData.columns.projects;
			initialData.tasks[task.id] = { id: task.id, content: task.fields.summary, link: "https://ircuwd.atlassian.net/browse/" + task.key, duedate: task.fields.duedate};
			set.taskIds.push(task.id);
                    } else {
                        var set = initialData.columns.openIssues;
			initialData.tasks[task.id] = { id: task.id, content: task.fields.summary, link: "https://ircuwd.atlassian.net/browse/" + task.key, duedate: task.fields.duedate};
			set.taskIds.push(task.id);
                    }
                }
                var set = null;
            });
            setAvailableSlots(maxAvailable - initialData.columns.main.taskIds.length);
            setState(initialData);
        }

    };

    useEffect(() => {
        getTickets();
    }, [viewUser]);

    const updateOrder = async (order) => {
        const params = new URLSearchParams({
            order: order,
            user: user
        });
        const str = params.toString();
        await ajaxCall('https://data.integrityprodserver.com/jira/updateTicketOrder.php?' + str, global.jiraToken);
    }

    const isMain = async (id, value) => {
        const params = new URLSearchParams({
            id: id,
            isMain: value
        });
        const str = params.toString();
        await ajaxCall('https://data.integrityprodserver.com/jira/isMain.php?' + str, global.jiraToken);
    }


    const onDragEnd = result => {
        const { destination, source, draggableId } = result;
        if (user && user != userInfo.email.split('@')[0]) {
            return;
        }

        if (state.tasks[draggableId]['duedate']) {
            return;
        }


        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (destination.droppableId == 'main' && source.droppableId == 'main' && state.tasks[draggableId]['duedate']) {
            return;
        }

        const start = state.columns[source.droppableId];
        const finish = state.columns[destination.droppableId];

        if (start.id == 'openIssues' || finish.id == 'openIssues') {
            return
        }

        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                ...state,
                columns: {
                    ...state.columns,
                    [newColumn.id]: newColumn,
                }
            };
            setState(newState);
            updateOrder(newState.columns[destination.droppableId].taskIds);
            return;
        }

        if (start.id == 'projects' && finish.id == 'main') {
            if (state.columns[destination.droppableId].taskIds.length < maxAvailable) {
                isMain(draggableId, true);
            } else {
                return;
            }
        } else {
            isMain(draggableId, false);
        }


        // Moving from one list to another


        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newState = {
            ...state,
            columns: {
                ...state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };
        setState(newState);
        updateOrder(newState.columns[source.droppableId].taskIds);
        updateOrder(newState.columns[destination.droppableId].taskIds);
        setAvailableSlots(maxAvailable - newState.columns.main.taskIds.length);
    }


    const changeUser = (data) => {
        setViewUser(data);
    }

    if (state.columnOrder) {
        return (
            <View style={styles.center}>
                <View>
                    {employees.length > 1 ?
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto' }}>
                            <Text>Employee:&nbsp;</Text>
                            <Select
                                defaultValue={user}
                                style={{ width: 120 }}
                                onChange={changeUser}
                                options={employees}
                            />
                        </View>
                        : <View />}
                </View>
                <DragDropContext
                    onDragEnd={onDragEnd}>
                    <div style={styles.container}>
                        {
                            state.columnOrder.map(columnId => {
                                const column = state.columns[columnId];
                                const tasks = column.taskIds.map(taskId => state.tasks[taskId]);
                                updateOrder(column.taskIds);
                                return <DroppableColumn key={column.id} column={column} tasks={tasks} availableSlots={availableSlots} />;
                            })
                        }
                    </div>
                </DragDropContext>
            </View>
        )
    } else {
        return (
            <View></View>
        )
    }

}

const styles = StyleSheet.create({
    center: {
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    container: {
        display: 'flex',
    },
});
